import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { OutboundLink } from "gatsby-plugin-google-gtag"
import * as imgPaidy3Values1 from './images/paidy_3_values_1.png';
import * as imgPaidy3Values2 from './images/paidy_3_values_2.png';
import * as imgPaidy3Values3 from './images/paidy_3_values_3.png';

import styles from './SectionPaidy3Values.module.scss';

const SectionPaidy3Values = () => (
  <Container maxWidth="lg">
    <Grid container className={styles.gridContainer}>
      <Grid item className={styles.ideaContainer} xs={12} md={6}>
        <div>
          <p className={styles.ideaDescription}>
            日本の将来に希望を抱いている若者は、たった 9.6%。
            <br />
            日本はいつから、“夢後進国”になってしまったのでしょうか。
            <br />
            <br />
            しかし、私たちは確信しています。
            <br />
            この国の人には、会社には、アイデアには、
            <br />
            まだまだ無限の可能性が潜んでいると。
          </p>
          <h1 className={styles.ideaTitle}>さあ、日本を“夢先進国”へ。</h1>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <h1 className={styles.threeValuesTitle}>
          ペイディが大切にする３つの価値
        </h1>
        <Grid container>
          <Grid item className={styles.valueContainer} xs={4} lg="auto">
            <OutboundLink
              href="#diversity&inclusion"
              className={styles.hyperlinkPaidy3Values}
            >
              <img
                src={imgPaidy3Values1}
                alt="Paidy 3 values 1"
                width={144}
                height={144}
                className={styles.imgPaidyValue}
              />
              <p className={styles.titleValue}>Diversity & Inclusion</p>
            </OutboundLink>
          </Grid>
          <Grid item className={styles.valueContainer} xs={4} lg="auto">
            <OutboundLink href="#think_bigger" className={styles.hyperlinkPaidy3Values}>
              <img
                src={imgPaidy3Values2}
                alt="Paidy 3 values 2"
                width={144}
                height={144}
                className={styles.imgPaidyValue}
              />
              <p className={styles.titleValue}>Think Bigger</p>
            </OutboundLink>
          </Grid>
          <Grid item className={styles.valueContainer} xs={4} md="auto">
            <OutboundLink href="#japan_matters" className={styles.hyperlinkPaidy3Values}>
              <img
                src={imgPaidy3Values3}
                alt="Paidy 3 values 3"
                width={144}
                height={144}
                className={styles.imgPaidyValue}
              />
              <p className={styles.titleValue}>Japan Matters</p>
            </OutboundLink>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Container>
);

export default SectionPaidy3Values;
