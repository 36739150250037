import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { OutboundLink } from "gatsby-plugin-google-gtag"
import imgBookCover from './images/book_cover.png';
import styles from './SectionStartup.module.scss';

const SectionStartup = () => (
  <div className={styles.container}>
    <div className={styles.bannerContainer}>
      <Container className={styles.containerTextBanner}>
        <h1 className={styles.titlePaidy}>
          Paidyのリーダーによる著書、発売中。
        </h1>
        <h1 className={styles.title}>スタートアップ・ニッポン</h1>
        <h2 className={styles.description}>最高に明るい未来を創る10のヒント</h2>
        <p className={styles.notes}>
          共著：代表取締役社長兼CEO 杉江陸・CMO コバリ・クレチマーリ・シルビア
        </p>
      </Container>
    </div>
    <Container>
      <Grid container className={styles.lotteryContainer}>
        <Grid item className={styles.rightTextContainer}>
          <p className={styles.lotteryText1}>
            これからを生きる、すべての若者たちにエールを。
            <br />
            <br />
            今すぐ実行可能な人生の「スタートアップ」プランをあなたに。
          </p>
          <p className={styles.lotteryBoldText}>
            発売を記念して、抽選で100名様に本書をプレゼント。
            <br />
            <br />
            ご希望の方は、
            <OutboundLink
              className={styles.pinkText}
              href="https://docs.google.com/forms/d/e/1FAIpQLScOzYfaIGNULjgcEdyJfM47_kNkwd4NHoVbZ9W_SkRmuO1UMg/viewform?vc=0&c=0&w=1&flr=0"
              target="_blank"
              rel="noreferrer"
            >
              こちらのフォーム
            </OutboundLink>
            よりお申し込みください。
          </p>
          <p className={styles.lotteryText2}>
            【フォーム内で回収する個人情報について】
            <br />
            <br />
            お申し込みフォーム内ではお名前・ご住所・お電話番号を伺いますが、本書プレゼントの発送目的のみに使用します。Paidyのプライバシーポリシーは
            <OutboundLink
              className={styles.pinkText}
              href="https://terms.paidy.com/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              こちらから
            </OutboundLink>
            ご確認いただけます。なお、お申し込みいただいた時点で、本ポリシーに同意いただいたものとします。
          </p>
        </Grid>
        <Grid item>
          <img
            className={styles.book}
            src={imgBookCover}
            alt="book cover"
            width={428}
            height={616}
          />
        </Grid>
      </Grid>
    </Container>
  </div>
);

export default SectionStartup;
