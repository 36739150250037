import React from 'react';
import styles from './VideoPlayer.module.scss';

const VideoPlayer = ({ url, onClose }) => (
  <div className={styles.container}>
    <button className={styles.closeBtn} onClick={onClose}>
      <img
        src={require('./images/icon_close_vid.png')}
        alt="Close Icon"
        height={60}
        width={60}
      />
    </button>
    <iframe
      className={styles.iframe}
      src={url}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </div>
);

export default VideoPlayer;
