import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import styles from './LinearBanner.module.scss';

const LinearBanner = () => (
  <div className={styles.linearBanner}>
    <Container className={styles.titleContainer}>
      <Grid container className={styles.gridContainer}>
        <Grid item>
          <h1 className={styles.title}>もう、“夢後進国”とは言わせない。</h1>
        </Grid>
      </Grid>
    </Container>
  </div>
);

export default LinearBanner;
