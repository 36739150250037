/* eslint-disable react/no-children-prop */
import React from 'react';
import Layout from 'src/components/Layout/Layout';
import { SITE_METADATA } from 'src/constants';
import LinearBanner from 'src/components/Dream/LinearBanner';
import SectionDaruma from 'src/components/Dream/SectionDaruma';
import SectionPaidy3Values from 'src/components/Dream/SectionPaidy3Values';
import SectionChairman from 'src/components/Dream/SectionChairman';
import SectionStartup from 'src/components/Dream/SectionStartup';

const Dream = (): JSX.Element => (
  <Layout
    SEOProps={{
      title: SITE_METADATA.dream.title,
      description: SITE_METADATA.dream.description,
    }}
  >
    <LinearBanner />
    <SectionDaruma />
    <SectionPaidy3Values />
    <SectionChairman />
    <SectionStartup />
  </Layout>
);

export default Dream;
