import React from 'react';
import cx from 'classnames';
import styles from './VideoButton.module.scss';

const VideoButton = ({
  className = '',
  background,
  onClick,
  height = 256,
  width = 552,
}) => (
  <button className={cx(styles.container, className)} onClick={onClick}>
    <img
      src={background}
      alt="Video Background"
      height={height}
      width={width}
    />
    <span className={styles.button}>
      <img
        className={styles.icon}
        src={require('./images/play-circle.png')}
        alt="Play Icon"
        width={73}
        height={73}
      />
    </span>
  </button>
);

export default VideoButton;
