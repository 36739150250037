/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Container, Grid, Paper } from '@material-ui/core';
import cx from 'classnames';
import VideoButton from './VideoButton';
import VideoPlayer from './VideoPlayer';
import * as imgPaidy3Values1 from './images/paidy_3_values_1.png';
import * as imgPaidy3Values2 from './images/paidy_3_values_2.png';
import * as imgPaidy3Values3 from './images/paidy_3_values_3.png';
import * as imgPaidy3ValuesVid1 from './images/paidy_3_values_vid_1.png';
import * as imgPaidy3ValuesVid2 from './images/paidy_3_values_vid_2.png';
import * as imgChairman1 from './images/chairman_1.png';
import * as imgChairman2 from './images/chairman_2.png';
import * as imgChairman3 from './images/chairman_3.png';
import * as imgThinkBiggerGraph from './images/think_bigger_graph.png';
import * as imgThinkBiggerGraphSmall from './images/think_bigger_graph_375.png';
import styles from './SectionChairman.module.scss';

const URL_EMBED_VID_3_VALUES_1 =
  'https://www.youtube.com/embed/FKdo19rS6cA?start=285';
const URL_EMBED_VID_3_VALUES_2 = 'https://www.youtube.com/embed/FKdo19rS6cA';

const SectionChairman1 = ({ onClickVideo = () => {} }) => (
  <div className={cx(styles.brown, styles.outer)}>
    <Container className={styles.container}>
      <Grid container className={styles.gridContainer}>
        <Grid item className={styles.valueContainer} xs={12} md={6}>
          <div id="diversity&inclusion" className={styles.anchor} />
          <img
            src={imgPaidy3Values1}
            alt="Paidy 3 values 1"
            width={144}
            height={144}
          />
          <h1 className={styles.valueTitle}>Diversity & Inclusion</h1>
          <p className={styles.valueDescription}>
            ダイバーシティこそ、Paidyのイノベーションの源泉。社員の国籍は30か国を超え、「六本木のシリコンバレー」と言われることも。自分の常識は、隣のメンバーの非常識。ユニークなスキルと熱いパッションを兼ね備えた“ラーニング・アニマル”たちが、日々常識を疑い、打破し、多様性のある独自のカルチャーを生み出しています。
          </p>
        </Grid>
        <Grid item className={styles.videoContainer} xs={12} md={6}>
          <VideoButton
            background={imgPaidy3ValuesVid1}
            onClick={onClickVideo}
            className={styles.imgDescription}
          />
        </Grid>
      </Grid>
    </Container>
    <Container className={styles.containerPaper}>
      <Paper className={styles.paper}>
        <Grid container className={styles.gridPaper}>
          <img
            width={320}
            height={320}
            src={imgChairman1}
            alt="chairman 1"
            className={styles.chairmanAvatar}
          />

          <Grid item className={styles.chairmanTextContainer}>
            <p className={styles.chairmanText}>
              日本でクレジットカードを作ることが出来なかった経験から、クレカの代替サービスを作ることを決意したのが2008年。カナダ人、インド人、イギリス人、日本人が集まって生まれた小さな会社で、私たちは「ペイディ」の原点となるアイデアを開発しました。重要なことは、そのスタートが、以降日本のお買い物事情をよりよく変えていくため取り組みを続ける非常に多様なチームを構築する始まりでもあったということです。
              <br />
              <br />
              私が信じる言葉に "strength lies in differences, not in
              similarities（強さは人と同じであることではなく、人と違うことから生まれる）"
              がありますが、異なる背景や価値観を持ってお互いに刺激し合うことは、まさにイノベーションの核心です。Paidyが多様なメンバーのもと独自の文化を作り上げていることを、誇りに思っています。
            </p>
            <p className={styles.chairmanNotesRight}>
              ラッセル・カマー
              <br />
              代表取締役会長
            </p>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  </div>
);

const SectionChairman2 = () => (
  <div className={cx(styles.outer)}>
    <Container className={styles.container}>
      <Grid container className={styles.gridContainer}>
        <Grid item className={styles.valueContainer} xs={12} md={6}>
          <div id="think_bigger" className={styles.anchor} />
          <img
            src={imgPaidy3Values2}
            alt="Paidy 3 values 2"
            width={144}
            height={144}
          />
          <h1 className={styles.valueTitle}>Think Bigger</h1>
          <p className={styles.valueDescription}>
            これまでも世界中の一流の投資家たちと、既成概念を打ち破ってきた、私たちPaidy。エグジットの手段としてIPOが主流の日本で、これまでにない大きな価値を創出するため、ペイパルグループの一員となることを選択。大胆な発想と大局的な視点で、まだ誰も見たことのない成長ストーリーを描き、桁違いの創造的イノベーションを実現していきます。
          </p>
          <p className={styles.valueNotes}>
            *出典: 令和2年度産業経済研究委託事業
            大企業×スタートアップのM&Aに関する調査報告書より抜粋し、株式会社Paidyが作成
          </p>
        </Grid>
        <Grid item className={styles.videoContainer} xs={12} md={6}>
          <picture>
            <source
              media="(max-width:960px)"
              srcSet={imgThinkBiggerGraphSmall}
            />
            <source media="(min-width:961px)" srcSet={imgThinkBiggerGraph} />
            <img
              src={imgThinkBiggerGraph}
              alt="Think bigger graph"
              width={552}
              height={256}
              className={styles.imgDescription}
            />
          </picture>
        </Grid>
      </Grid>
    </Container>
    <Container className={styles.containerPaper}>
      <Paper className={styles.paperAvatarLeft}>
        <Grid container className={styles.gridPaperReverse}>
          <img
            width={320}
            height={320}
            src={imgChairman2}
            alt="chairman 2"
            className={styles.chairmanAvatarRight}
          />
          <Grid item className={styles.chairmanTextContainerMiddle}>
            <p className={styles.chairmanText}>
              「Riku &
              Russはどうしたい？」ーこれまで私たちを支えてくれた事業会社株主を含めた投資家の皆さんが、常に問いかけてくれた質問です。
              <br />
              <br />
              このたびのペイパルグループ入りについても、最後は全員が「君たちがやりたいようにやっていい、もっと大きく世界を変えてくれ」と言ってくれました。私たちは、世界の超一流のライバルと、対等に勝負したい。その想いを実現するためには、この選択肢がベスト、と考えたのです。
              <br />
              <br />
              売上が3年で10倍になり、日本随一のBNPLになったペイディ。ペイパルグループとして1,000万人を大きく超えるお客様にご利用いただくサービスになりました。・・・ここで立ち止まってなるものですか。日本を「夢先進国」にするためにも、私たちはもっと大きな夢を追い続けます。
            </p>
            <p className={styles.chairmanNotesLeft}>
              杉江 陸
              <br />
              代表取締役社長兼CEO
            </p>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  </div>
);

const SectionChairman3 = ({ onClickVideo = () => {} }) => (
  <div className={cx(styles.brown, styles.outer)}>
    <Container className={styles.container}>
      <Grid container className={styles.gridContainer}>
        <Grid item className={styles.valueContainer} xs={12} md={6}>
          <div id="japan_matters" className={styles.anchor} />
          <img
            src={imgPaidy3Values3}
            alt="Paidy 3 values 3"
            width={144}
            height={144}
          />
          <h1 className={styles.valueTitle}>Japan Matters</h1>
          <p className={styles.valueDescription}>
            この国は今も、世界が注目する第三の経済大国。美しい国のひとつとして評価され、美味しい食事や風光明媚な景観を備えたこの国は、世界中から多彩な才能を強烈に惹きつけるパワーがあります。そしてこれからも、この国は魅力的であり続けられる。私たちはそう信じています。さあ日本を、私たちと一緒に、誰もが夢を叶えられる“夢先進国”へ。
          </p>
        </Grid>
        <Grid item className={styles.videoContainer} xs={12} md={6}>
          <VideoButton
            background={imgPaidy3ValuesVid2}
            onClick={onClickVideo}
            className={styles.imgDescription}
          />
        </Grid>
      </Grid>
    </Container>
    <Container className={styles.containerPaper}>
      <Paper className={styles.paper}>
        <Grid container className={styles.gridPaper}>
          <img
            width={320}
            height={320}
            src={imgChairman3}
            alt="chairman 3"
            className={styles.chairmanAvatar}
          />
          <Grid item className={styles.chairmanTextContainer}>
            <p className={styles.chairmanText}>
              「金融サービスの民主化」を使命としているペイパルは、誰にとってもハードルのない金融サービスを利用できる社会を目指しています。
              <br />
              <br />
              これからペイパルはPaidyと力を合わせながら日本の魅力的な商品やコンテンツを世界に広げ、「安心・安全・快適なデジタル決済」をお客さまにお届けし、加盟店の皆さまのビジネスに寄与してまいります。
              <br />
              <br />
              オンラインショッピングが当たり前になっている今だからこそ、私たちは本当に必要とされるサービスで日本経済の活性化に貢献していきたいと思います。
            </p>
            <p className={styles.chairmanNotesRight}>
              ピーター・ケネバン
              <br />
              ペイパル 日本事業統括責任者
            </p>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  </div>
);

const SectionChairman = () => {
  const [videoUrl, setVideoUrl] = useState(null);

  return (
    <>
      {videoUrl && (
        <VideoPlayer url={videoUrl} onClose={() => setVideoUrl(null)} />
      )}
      <SectionChairman1
        onClickVideo={() => setVideoUrl(URL_EMBED_VID_3_VALUES_1)}
      />
      <SectionChairman2 />
      <SectionChairman3
        onClickVideo={() => setVideoUrl(URL_EMBED_VID_3_VALUES_2)}
      />
    </>
  );
};

export default SectionChairman;
