import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import * as imgDaruma from './images/daruma.png';
import * as imgGraph from './images/graph.png';
import * as imgGraphSmall from './images/graph_343.svg';
import styles from './SectionDaruma.module.scss';

const SectionDaruma = () => (
  <Container className={styles.container}>
    <Grid container className={styles.gridContainer}>
      <Grid item className={styles.darumaContainer} xs={12} md={6}>
        <img
          src={imgDaruma}
          alt="Daruma"
          className={styles.darumaLogo}
          width={531}
        />
      </Grid>
      <Grid item className={styles.graphContainer} xs={12} md={6}>
        <picture>
          <source media="(max-width:960px)" srcSet={imgGraphSmall} />
          <source media="(min-width:961px)" srcSet={imgGraph} />
          <img
            src={imgGraph}
            alt="Graph"
            className={styles.graph}
            width={560}
            height={307}
          />
        </picture>
        <p className={styles.graphDescription}>
          出典: 日本財団「18歳意識調査」第20回
          <br />
          テーマ:「国や社会に対する意識」（9カ国調査）を加工し株式会社Paidyが作成。
        </p>
      </Grid>
    </Grid>
  </Container>
);

export default SectionDaruma;
